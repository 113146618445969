import React from "react";
import { useRoutes } from "react-router-dom";
import NotFound from "pages/NotFound";
import Home1 from "pages/Home1";
import Features from "pages/Features";
import Contact from "pages/Contact";

const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "/", element: <Home1 /> },
    { path: "*", element: <NotFound /> },
    {
      path: "features",
      element: <Features />,
    },
    /*{
      path: "contact",
      element: <Contact />,
    },*/
  ]);

  return element;
};

export default ProjectRoutes;
