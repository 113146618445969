import { Text, Img } from "./..";
import React from "react";

export default function PriceComparisonComponent({
  comparisonImage = "images/img_depth_8_frame_0.png",
  comparisonTitle = "Compare prices",
  comparisonDescription = "Find the best price by comparing from 10,000+ retailers",
  ...props
}) {
  return (
    <div {...props} className={`${props.className} flex flex-col items-center w-[20%] md:w-full gap-3`}>
      <Img src={comparisonImage} alt="Price Image" className="h-[98px] w-full rounded-[12px] object-cover" />
      <div className="self-stretch">
        <div className="flex">
          <Text as="p" className="text-[16px] font-medium text-[#111416]">
            {comparisonTitle}
          </Text>
        </div>
        <Text size="textxs" as="p" className="text-[14px] font-normal leading-[21px] text-[#607289]">
          {comparisonDescription}
        </Text>
      </div>
    </div>
  );
}
