import React from "react";

const sizes = {
  headingxs: "text-[16px] font-bold",
  headings: "text-[18px] font-bold",
  headingmd: "text-[22px] font-bold",
  headinglg: "text-[32px] font-bold md:text-[30px] sm:text-[28px]",
  headingxl: "text-[36px] font-black md:text-[34px] sm:text-[32px]",
  heading2xl: "text-[48px] font-black md:text-[44px] sm:text-[38px]",
};

const Heading = ({ children, className = "", size = "headingxl", as, ...restProps }) => {
  const Component = as || "h6";

  return (
    <Component className={`text-[#141414] font-['Work_Sans'] ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Heading };
