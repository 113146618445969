import { Helmet } from "react-helmet";
import { Button, Text, Img, Heading } from "../../components";
import Header from "../../components/Header";
import PriceComparisonComponent from "../../components/PriceComparisonComponent";
import React, { Suspense } from "react";
import { useNavigate } from 'react-router-dom';

import {
  AccordionItemPanel,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemState,
  Accordion,
  AccordionItem,
} from "react-accessible-accordion";

const depthFrameList = [
  { frameImageOne: "images/img_depth_7_frame_0_300x300.png" },
  { frameImageOne: "images/img_depth_7_frame_0_3.png" },
  { frameImageOne: "images/img_depth_7_frame_0_4.png" },
];
const priceComparisonList = [
  {
    comparisonImage: "images/img_depth_8_frame_0.png",
    comparisonTitle: "Compare prices",
    comparisonDescription: "Find the best price by comparing retailers.",
  },
  {
    comparisonImage: "images/img_depth_8_frame_0_98x176.png",
    comparisonTitle: "Track price changes",
    comparisonDescription: "Save money by tracking price changes over time.",
  },
  {
    comparisonImage: "images/img_depth_8_frame_0_1.png",
    comparisonTitle: "Scan barcodes",
    comparisonDescription: "Scan any product’s barcode to compare prices.",
  },
  {
    comparisonImage: "images/img_depth_8_frame_0_2.png",
    comparisonTitle: "Add to PricePal cart",
    comparisonDescription: "Add products from any site to your PricePal cart.",
  },
  /*{
    comparisonImage: "images/img_depth_8_frame_0_3.png",
    comparisonTitle: "Get cashback",
    comparisonDescription: "Earn 1% cashback at 1000+ stores.",
  },*/
];
const accordionData = [
  {
    freeQuestion: "Is PricePal free?",
    answer: "Yes, PricePal is 100% free to use."
  },
  {
    freeQuestion: "How does PricePal work?",
    answer: "PricePal helps users compare prices of products across multiple stores, allowing them to track item availability and find the best deals in one place. It retrieves and updates price data dynamically to ensure accuracy for shoppers."
  },
  /*{
    freeQuestion: "How do I use PricePal?",
  },*/
];

export default function FeaturesPage() {
  const navigate = useNavigate()

  return (
    <>
      <Helmet>
        <title>Features</title>
        <meta
          name="description"
          content="PricePal helps you find and compare the best prices for products across multiple stores. Easily track items, check store availability, and get the best deals all in one place."
        />
      </Helmet>
      <div className="w-full bg-[#ffffff]">
        <div className="bg-[#ffffff]">
          <Header />
          <div className="flex flex-col items-center py-5">
            <div className="container-xs md:px-5">
              <div className="py-3">
                <div className="px-4">
                  <div className="flex h-[480px] flex-col items-start gap-8 rounded-[12px] bg-gradient1 bg-cover bg-no-repeat p-6 md:h-auto sm:p-5">
                    <div className="ml-8 mt-[236px] flex flex-col items-center gap-2 self-stretch md:ml-0">
                      <Heading
                        size="heading2xl"
                        as="h1"
                        className="text-[48px] font-black tracking-[-2.00px] text-[#ffffff] md:text-[44px] sm:text-[38px]"
                      >
                        PricePal: The shopping app you need
                      </Heading>
                      <Text as="p" className="w-full text-[16px] font-normal leading-6 text-[#ffffff]">
                      PricePal helps you find and compare the best prices for products across multiple stores. Easily track items, check store availability, and get the best deals all in one place.
                      </Text>
                    </div>
                    <Button
                      color="blue_600"
                      shape="round"
                      className="ml-8 min-w-[132px] rounded-[12px] px-5 font-bold md:ml-0"
                      onClick={() => navigate("/")}
                    >
                      Get the app
                    </Button>
                  </div>
                </div>
                <div className="mt-14 flex flex-col gap-10 px-4">
                  <div className="flex flex-col gap-4">
                    <div className="flex">
                      <Heading
                        as="h2"
                        className="text-[36px] font-black tracking-[-1.00px] text-[#111416] md:text-[34px] sm:text-[32px]"
                      >
                        Key features
                      </Heading>
                    </div>
                    <Text as="p" className="w-[78%] text-[16px] font-normal leading-6 text-[#111416] md:w-full">
                      PricePal is packed with features that make shopping easier and cheaper. Here are some of our
                      favorites.
                    </Text>
                  </div>
                  <div className="flex flex-col gap-3">
                    <div>
                      <div className="mb-3 flex gap-3 md:flex-col">
                        <Suspense fallback={<div>Loading feed...</div>}>
                          {priceComparisonList.map((d, index) => (
                            <PriceComparisonComponent {...d} key={"comparisonList" + index} />
                          ))}
                        </Suspense>
                      </div>
                    </div>
                  </div>
                </div>
                {/*<div className="mx-4 mt-14 flex gap-3 md:mx-0 md:flex-col">
                  <Suspense fallback={<div>Loading feed...</div>}>
                    {depthFrameList.map((d, index) => (
                      <Img
                        key={"imageList" + index}
                        src={d.frameImageOne}
                        alt="Frame Image One"
                        className="h-[300px] w-[32%] rounded-[12px] object-contain md:w-full"
                      />
                    ))}
                  </Suspense>
                </div>*/}
                <div className="mt-4 flex px-4 py-3">
                  <Heading size="headingmd" as="h3" className="mt-2 text-[22px] font-bold text-[#111416]">
                    FAQ
                  </Heading>
                </div>
                <div className="mt-4 flex flex-col items-start gap-7 px-4">
                <Accordion preExpanded={[0]} className="flex flex-col gap-3 self-stretch">
                  {accordionData.map((d, i) => (
                    <AccordionItem uuid={i} key={`FAQ List${i}`}>
                      <div className="flex-1 rounded-[12px] bg-[#eff2f4] p-2">
                        <AccordionItemHeading className="w-full">
                          <AccordionItemButton>
                            <AccordionItemState>
                              {(props) => (
                                <>
                                  <div className="flex flex-wrap items-center justify-between gap-5 py-2">
                                    <Text size="textxs" as="p" className="text-[14px] font-medium text-[#111416]">
                                      {d.freeQuestion}
                                    </Text>
                                    {props?.expanded ? (
                                      <Img
                                        src="images/img_arrow_down.svg"
                                        alt="Free Icon"
                                        className="h-[20px] w-[20px]"
                                      />
                                    ) : (
                                      <Img
                                        src="images/img_arrow_down.svg"
                                        alt="Work Icon"
                                        className="h-[20px] w-[20px]"
                                      />
                                    )}
                                  </div>
                                </>
                              )}
                            </AccordionItemState>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="mb-2">
                            <Text
                              size="textxs"
                              as="p"
                              className="text-[14px] font-normal leading-[21px] text-[#607289]"
                            >
                              {d.answer} {/* Show dynamic answer from accordionData */}
                            </Text>
                          </div>
                        </AccordionItemPanel>
                      </div>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
