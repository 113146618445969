import { Heading, Img } from "./..";
import React from "react";

export default function ProductBrowser({
  productImage = "images/img_search.svg",
  productTitle = "Browse products",
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col items-start w-[32%] md:w-full gap-3 p-4 border-[#dbe0e5] border border-solid bg-[#ffffff] rounded-lg`}
    >
      <Img src={productImage} alt="Search Icon" className="h-[24px] w-[24px]" />
      <Heading size="headingxs" as="h6" className="text-[16px] font-bold text-[#141414]">
        {productTitle}
      </Heading>
    </div>
  );
}
