import React from "react";

const sizes = {
  textxs: "text-[14px] font-normal",
  texts: "text-[16px] font-normal not-italic",
};

const Text = ({ children, className = "", as, size = "texts", ...restProps }) => {
  const Component = as || "p";

  return (
    <Component className={`text-[#141414] font-['Work_Sans'] ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Text };
