import { Heading, Img } from "./..";
import React from "react";
import { Link } from 'react-router-dom';


export default function Header({ ...props }) {
  return (
    <header
      {...props}
      className={`${props.className} flex justify-between items-center gap-5 px-10 py-3 sm:px-5 border-[#e5e8ea] border-b border-solid`}
    >
      <div className="flex items-center gap-4">
        <a href="#">
          <Img
            src="pricepal.svg"
            alt="Refresh Icon"
            className="h-[32px] w-[32px]"
          />
        </a>
        <Heading
          size="headings"
          as="h6"
          className="text-[18px] font-bold text-[#141414]"
        >
          PricePal
        </Heading>
      </div>
      <div className="flex gap-2">
        <div className="flex items-center gap-9">
          <Link
            className="text-[#141414] text-sm font-medium leading-normal hover:text-blue-500"
            to="/"
          >
            Home
          </Link>
          <Link
            className="text-[#141414] text-sm font-medium leading-normal hover:text-blue-500"
            to="/features"
          >
            Features
          </Link>
          {/*<Link
            className="text-[#141414] text-sm font-medium leading-normal hover:text-blue-500"
            to="/contact"
          >
            Contact
          </Link>*/}
        </div>
      </div>
    </header>
  );
}