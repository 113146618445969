import { Helmet } from "react-helmet";
import { Button, Heading, Text } from "../../components";
import Header from "../../components/Header";
import ProductBrowser from "../../components/ProductBrowser";
import React, { Suspense, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { FaGithub } from 'react-icons/fa';
import { joinWaitlist } from "services/api";

const browseOptionsList = [
  { productImage: "images/img_search.svg", productTitle: "Browse products" },
  { productImage: "images/img_megaphone.svg", productTitle: "Compare prices" },
  { productImage: "images/img_megaphone_gray_900.svg", productTitle: "Save money" },
];
/*const depthFrameList = [
  { depthFrameImage: "images/" },
  { depthFrameImage: "images/" },
  { depthFrameImage: "images/" },
  { depthFrameImage: "images/" },
];*/

export default function Home1Page() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      /*const response = await fetch('https://api.pricepalapp.com/api/v1/join-waitlist', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'X-API-Key': '25fd086fe09b1e83a261e393c2ab34519f8229f3de5da10c358dc3015155aed9'
        },
        body: JSON.stringify({ email }),
      });      
      const data = await response.json();*/
      const response = await joinWaitlist(email);
      //const data = { success: true}
      if (response.status === 201) {
        setMessage('You’ve successfully joined the waitlist!');
      } else {
        setMessage('There was an error. Please try again.');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <>
      <Helmet>
        <title>Home | PricePal</title>
        <meta
          name="description"
          content="Join PricePal's open-source waitlist to discover the best local deals on groceries and essentials. Compare prices, save money, and contribute to our app launch."
        />
      </Helmet>
      <div className="w-full bg-[#ffffff]">
        <div className="bg-[#ffffff]">
          <Header />
          <div className="flex flex-col items-center py-5">
            <div className="container-xs md:px-5">
              <div className="flex flex-col gap-4">
                <div className="mt-4 px-4">
                  <div className="flex h-[480px] flex-col gap-8 rounded-[12px] bg-gradient bg-cover bg-no-repeat p-6 md:h-auto sm:p-5">
                    <div className="ml-8 mt-[236px] flex flex-col gap-2 md:ml-0">
                      <div className="flex">
                        <Heading
                          size="heading2xl"
                          as="h1"
                          className="text-[48px] font-black tracking-[-2.00px] text-[#ffffff] md:text-[44px] sm:text-[38px]"
                        >
                          Find the Best Prices Locally
                        </Heading>
                      </div>
                      <Text as="p" className="text-[16px] font-normal leading-6 text-[#ffffff]">
                        PricePal is an open-source app that helps you find the best prices for your groceries. Explore our source code on{' '}
                      </Text>
                    </div>
                    <div className="mx-8 flex gap-3 flex-wrap md:mx-0 sm:flex-col">
                      <a
                        href="https://github.com/decompi/pricepal"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="View PricePal on GitHub"
                        className="flex-none"
                      >
                        <Button 
                          color="blue_400" 
                          shape="round" 
                          className="min-w-[170px] rounded-[12px] px-5 font-bold flex items-center justify-center gap-2"
                        >
                          <FaGithub size={20} />
                          View on GitHub
                        </Button>
                      </a>
                      <form onSubmit={handleSubmit} className="flex gap-3 sm:flex-col sm:items-stretch">
                          <input
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="min-w-[200px] px-3 py-2 rounded-[12px] border border-gray-400 sm:w-full"
                          />
                          <Button type="submit" color="blue_400" shape="round" className="min-w-[170px] rounded-[12px] px-5 font-bold sm:w-full">
                            Join the Waitlist
                          </Button>
                        </form>
                        {message && <p className="text-white mt-2">{message}</p>}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-10 px-4 py-10 sm:py-5">
                  <div className="flex flex-col items-start gap-6">
                    <div className="flex flex-col gap-4 self-stretch">
                      <div className="flex">
                        <Heading
                          as="h2"
                          className="text-[36px] font-black tracking-[-1.00px] text-[#141414] md:text-[34px] sm:text-[32px]"
                        >
                          How it Works
                        </Heading>
                      </div>
                      <Text as="p" className="w-[78%] text-[16px] font-normal leading-6 text-[#141414] md:w-full">
                        Simply type in a product, browse through the results, and see the prices at different stores.
                        Find the best deal and save money.
                      </Text>
                    </div>
                    <Button 
                    color="blue_400" 
                    shape="round" 
                    className="min-w-[130px] rounded-[12px] px-5 font-bold"
                    onClick={() => navigate("/features")}
                    >
                      Learn more
                    </Button>
                  </div>
                  <div className="flex gap-3 md:flex-col">
                    <Suspense fallback={<div>Loading feed...</div>}>
                      {browseOptionsList.map((d, index) => (
                        <ProductBrowser {...d} key={"featuresList" + index} />
                      ))}
                    </Suspense>
                  </div>
                </div>
                <div className="flex flex-col gap-4">
                  {/*<div className="mx-4 flex gap-3 md:mx-0 md:flex-col">
                    <Suspense fallback={<div>Loading feed...</div>}>
                      {depthFrameList.map((d, index) => (
                        <Img
                          key={"imagesList" + index}
                          src={d.depthFrameImage}
                          alt="Depth Frame Image"
                          className="h-[222px] w-[24%] rounded-[12px] object-contain md:w-full"
                        />
                      ))}
                    </Suspense>
                  </div>*/}
                  <div className="flex flex-col gap-8 px-10 py-20 md:py-5 sm:p-5">
                    <div>
                      <div className="flex pl-[164px] pr-14 md:px-5">
                        <Heading
                          as="h3"
                          className="text-[36px] font-black tracking-[-1.00px] text-[#141414] md:text-[34px] sm:text-[32px]"
                        >
                          Get PricePal on mobile
                        </Heading>
                      </div>
                    </div>
                    <div className="flex justify-center px-14 md:px-5">
                      <div className="flex items-start gap-3">
                        <Button
                          color="blue_400"
                          shape="round"
                          className="mb-[60px] min-w-[234px] rounded-[12px] px-2 font-bold"
                        >
                          Coming soon {/** Download on the App Store */}
                        </Button>
                        {<Button shape="round" className="min-w-[234px] rounded-[12px] px-[34px] font-bold sm:px-5">
                          Coming soon  {/** Get it on Google Play */}
                        </Button>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}